import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import type { WrapPageElementNodeArgs } from "gatsby";
import { MainProvider } from "./MainContext";
//@ts-ignore
import theme from "./styles/theme";
export const WrapRootElement = ({
	element,
}: Pick<WrapPageElementNodeArgs, "element">) => (
	<MainProvider>
		<ChakraProvider theme={theme}>{element}</ChakraProvider>
	</MainProvider>
);
