import { InfluencerProps } from "../components/home/TopInfluencer";

const BASE_URL = "https://insanedev.yomi.dance/api/";
const IMAGE_URL = "https://insanedev.yomi.dance/contents/";
/*region interfaces*/

interface Home {
	id: number;
	media_presentazione_url: string;
	titolo_presentazione_it: string;
	titolo_it: string;
	sottotitolo_it: string;
	media_orizzontale_url: string;
	video_logo_url: string;
	media_presentazione: string;
	titolo_2_it: string;
	descrizione_2_it: string;
	titolo_presentazione_en: string;
	titolo_en: string;
	sottotitolo_en: string;
	titolo_2_en: string;
	servizi_label_it: string;
	servizi_label_en: string;
	descrizione_2_en: string;
}

interface Service {
	id: number;
	titolo_it: string;
	descrizione_it: string;
	service_media_url: string;
	titolo_en: string;
	descrizione_en: string;
}

interface Talent {
	id: number;
	nome: string;
	cognome: string;
	creator_name: string;
	collaborazione: "SI" | "NO" | "Si" | "No" | string;
	collaborazione_en: "SI" | "NO" | "Si" | "No" | string;
	categoria_it: string;
	sotto_categoria_it: string;
	avatar: string;
	descrizione_it: string;
	link_twitter: string | null;
	twitter_followers: string;
	link_instagram: string | null;
	instagram_followers: string;
	link_tiktok: string | null;
	tiktok_followers: string;
	url_it: string;
	url_en: string;
	link_facebook: string | null;
	facebook_followers: string;
	link_youtube: string;
	youtube_followers: string;
	contenuto_verticale_uno_url: string;
	contenuto_verticale_due_url: string;
	contenuto_verticale_tre_url: string;
	contenuto_orizzontale_uno_url: string;
	contenuto_orizzontale_due_url: string;
	contenuto_orizzontale_tre_url: string;
	categoria_en: string;
	sotto_categoria_en: string;
	descrizione_en: string;
	highlight: string;
}

interface SuccessCase {
	id: number;
	media_principale_url: string;
	nome_progetto_it: string;
	titolo_it: string;
	descrizione_titolo_it: string;
	tipologia_it: string;
	visualizzazioni_box_descrizione_it: string;
	visualizzazioni_numero: number;
	like_box_descrizione_it: string;
	like_numero: number;
	talenti_box_descrizione: string;
	talenti_numero: number;
	media_landing_url: string;
	titolo_media_landing_it: string;
	descrizione_media_landing_it: string;
	content_1_vertical_url: string;
	content_2_vertical_url: string;
	content_3_vertical_url: string;
	cases_1: string | null;
	cases_2: string | null;
	cases_3: string | null;
	cases_4: string | null;
	nome_progetto_en: string;
	titolo_en: string;
	descrizione_titolo_en: string;
	tipologia_en: string;
	visualizzazioni_box_descrizione_en: string;
	like_box_descrizione_en: string;
	titolo_media_landing_en: string;
	descrizione_media_landing_en: string | null;
	caso_successo: string;
}

interface Sponsor {
	id: number;
	nome: string;
	logo_media_pic: string;
}

interface HomeData {
	homes: Home[];
	services: Service[];
	talents: Talent[];
	success_cases: SuccessCase[];
	sponsors: Sponsor[];
}

/*endregion*/

const getHomeData = async (): Promise<HomeData> => {
	const response = await fetch(BASE_URL + "home");
	const data = await response.json();
	return data;
};
const getTalents = async (): Promise<Talent[]> => {
	const response = await fetch(BASE_URL + "talents");
	const data = await response.json();
	return data;
};
const normalizeNumberOfSubscribers = (numberOfSubscribers: string): number => {
	if (!numberOfSubscribers) return 0;
	// Rimuove tutti gli spazi e sostituisce le virgole con punti
	let normalized = numberOfSubscribers.replace(/\s/g, '').replace(',', '.');

	// Converte M (milioni) e K (migliaia)
	if (normalized.endsWith('M')) {
		return parseFloat(normalized) * 1000000;
	} else if (normalized.endsWith('K')) {
		return parseFloat(normalized) * 1000;
	}

	// Rimuove tutti i punti (per gestire numeri come 1.600.000)
	normalized = normalized.replace(/\./g, '');

	// Converte in numero
	const number = parseInt(normalized, 10);
	if (isNaN(number)) return 0;
	return number;
};
const fromTalentToInfluencer = (
	talent: Talent,
	language: string
): InfluencerProps => {
	const greatestFollowers = Math.max(normalizeNumberOfSubscribers(talent.tiktok_followers), normalizeNumberOfSubscribers(talent.facebook_followers), normalizeNumberOfSubscribers(talent.youtube_followers), normalizeNumberOfSubscribers(talent.instagram_followers), normalizeNumberOfSubscribers(talent.twitter_followers));
	const greatestFollowersLabel = greatestFollowers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	let collaborations = talent.collaborazione?.toUpperCase() !== "NO" ? talent.collaborazione : null;
	if(language === "en"){
		collaborations = talent.collaborazione_en?.toUpperCase() !== "NO" ? talent.collaborazione_en : null;
	}
	return {
		id: talent.id,
		fullName: talent.nome + " " + talent.cognome,
		name: talent.creator_name,
		collaborations: collaborations,
		image: talent.avatar,
		slug: language === "en" ? talent.url_en : talent.url_it,
		category: language === "en" ? talent.categoria_en : talent.categoria_it, // TODO: fix
		subcategory:
			language === "en"
				? talent.sotto_categoria_en
				: talent.sotto_categoria_it,
		categoryValue: talent.categoria_en,
		subcategoryValue: talent.sotto_categoria_en,
		description:
			language === "en" ? talent.descrizione_en : talent.descrizione_it,
		twitter: talent.link_twitter || undefined,
		instagram: talent.link_instagram
			? talent.link_instagram?.indexOf("https") === 0
				? talent.link_instagram
				: "https://" + talent.link_instagram
			: undefined,
		tiktok: talent.link_tiktok
			? talent.link_tiktok?.indexOf("https") === 0
				? talent.link_tiktok
				: "https://" + talent.link_tiktok
			: undefined,
		facebook: talent.link_facebook
			? talent.link_facebook?.indexOf("https") === 0
				? talent.link_facebook
				: "https://" + talent.link_facebook
			: undefined,
		youtube: talent.link_youtube
			? talent.link_youtube.indexOf("https") === 0
				? talent.link_youtube
				: "https://" + talent.link_youtube
			: undefined,
		tiktok_followers: normalizeNumberOfSubscribers(talent.tiktok_followers),
		facebook_followers: normalizeNumberOfSubscribers(talent.facebook_followers),
		youtube_followers: normalizeNumberOfSubscribers(talent.youtube_followers),
		greatest_followers: greatestFollowersLabel,
		instagram_followers: normalizeNumberOfSubscribers(talent.instagram_followers),
		twitter_followers: normalizeNumberOfSubscribers(talent.twitter_followers),
		//normalized with .000 todo:pagina talent singola
		tiktok_followers_label: normalizeNumberOfSubscribers(talent.tiktok_followers).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
		facebook_followers_label: normalizeNumberOfSubscribers(talent.facebook_followers).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
		youtube_followers_label: normalizeNumberOfSubscribers(talent.youtube_followers).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
		instagram_followers_label: normalizeNumberOfSubscribers(talent.instagram_followers).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
		twitter_followers_label: normalizeNumberOfSubscribers(talent.twitter_followers).toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."),
		image_vertical: talent.contenuto_verticale_uno_url,
		image_vertical_due: talent.contenuto_verticale_due_url,
		image_vertical_tre: talent.contenuto_verticale_tre_url,
		image_orizzontale: talent.contenuto_orizzontale_uno_url,
		image_orizzontale_due: talent.contenuto_orizzontale_due_url,
		image_orizzontale_tre: talent.contenuto_orizzontale_tre_url,
		website: undefined,
		linkedin: undefined,
		subscribers: talent.instagram_followers,
	};
};
export {
	BASE_URL,
	getHomeData,
	HomeData,
	Talent,
	getTalents,
	IMAGE_URL,
	fromTalentToInfluencer,
};

interface Category {
	id: number;
	name_it: string;
	name_en: string;
}

interface Sottocategory {
	id: number;
	name_it: string;
	name_en: string;
}

interface TalentDataProps {
	talents: Talent[];
	categories: Category[];
	sottocategories: Sottocategory[];
}

interface TalentPageDataProps {
	page_talents:{titolo_it: string;
	titolo_en: string;
	sottotitolo_it: string;
	sottotitolo_en: string;
}[]
}
export { TalentDataProps, Category, Sottocategory, TalentPageDataProps };
interface AboutSection {
	id: number;
	media_principale_url: string;
	titolo_uno_it: string;
	media_quattro: string;
	media_tre: string;
	media_due: string;
	media_uno: string;
	media_team: string;
	descrizione_uno_it: string;
	titolo_uno_en: string;
	descrizione_uno_en: string;
	titolo_due_it: string;
	titolo_due_en: string;
	descrizione_due_it: string;
	descrizione_due_en: string;
	titolo_tre_it: string;
	titolo_tre_en: string;
	descrizione_tre_it: string;
	descrizione_tre_en: string;
	titolo_quattro_it: string;
	titolo_quattro_en: string;
	descrizione_quattro_it: string;
	descrizione_quattro_en: string;
	media_team_url: string;
	media_due_url: string;
	media_tre_url: string;
	media_quattro_url: string;
}

interface TeamMember {
	id: number;
	nome: string;
	cognome: string;
	avatar: string;
	ruolo_it: string;
	mini_avatar?: string;
	descrizione_it: string;
	link_facebook: string | null;
	link_instagram: string | null;
	link_linkedIn: string | null;
	link_twitter: string | null;
	ruolo_en: string;
	descrizione_en: string;
}

interface AboutDataStructure {
	about: AboutSection[];
	team: TeamMember[];
}
interface TeamMemberProps {
	id: number;
	name: string;
	avatarImage: string;
	hoverImage?: string;
	role: string;
	roleEn: string;
	descriptionIt: string;
	descriptionEn: string;
	image: string;
	instagram: string | null;
	twitter: string | null;
	linkedin: string | null;
}
function getAboutData(): Promise<AboutDataStructure> {
	return fetch(BASE_URL + "about")
		.then(res => res.json())
		.then(data => data);
}

const fromTeamToMember = (
	teamMember: TeamMember,
	language: string
): TeamMemberProps => {
	return {
		id: teamMember.id,
		name: teamMember.nome,
		role: language === "it" ? teamMember.ruolo_it : teamMember.ruolo_en,
		roleEn: teamMember.ruolo_en,
		descriptionIt: teamMember.descrizione_it,
		descriptionEn: teamMember.descrizione_en,
		image: IMAGE_URL + teamMember.avatar,
		hoverImage: IMAGE_URL + teamMember.mini_avatar,
		avatarImage: IMAGE_URL + teamMember.mini_avatar,
		instagram: teamMember.link_instagram,
		twitter: teamMember.link_twitter,
		linkedin: teamMember.link_linkedIn,
	};
};
export { getAboutData, AboutDataStructure, fromTeamToMember, TeamMemberProps };
interface ProjectIntestation {
	id: number;
	titolo_it: string;
	titolo_en: string;
	sotto_titolo_it: string;
	sotto_titolo_en: string;
	titolo_influencer_it: string;
	titolo_influencer_en: string;
	descrizione_influencer_it: string;
	descrizione_influencer_en: string;
	influencer_photo: string;
	titolo_brand_it: string;
	titolo_brand_en: string;
	descrizione_brand_it: string;
	descrizione_brand_en: string;
	brand_photo: string;
}

interface ServicesCategory {
	id: number;
	nome_it: string;
	nome_en: string;
}

interface OccupyTab {
	id: number;
	titolo_it: string;
	titolo_en: string;
	descrizione_it: string;
	descrizione_en: string;
}

interface BrandTab {
	id: number;
	titolo_it: string;
	titolo_en: string;
	descrizione_it: string;
	descrizione_en: string;
}

interface OrganizeEvent {
	id: number;
	event_media: string;
	titolo_it: string;
	titolo_en: string;
	descrizione_it: string;
	descrizione_en: string;
	titolo_box_uno_it: string;
	titolo_box_uno_en: string;
	descrizione_box_uno_it: string;
	descrizione_box_uno_en: string;
	titolo_box_due_it: string;
	titolo_box_due_en: string;
	descrizione_box_due_it: string;
	descrizione_box_due_en: string;
	titolo_box_tre_it: string;
	titolo_box_tre_en: string;
	descrizione_box_tre_it: string;
	descrizione_box_tre_en: string;
}

interface DiscoverStudio {
	id: number;
	studio_media: string;
	titolo_it: string;
	titolo_en: string;
	descrizione_uno_it: string;
	descrizione_uno_en: string;
}

interface AdditiveService {
	id: number;
	titolo_it: string;
	titolo_en: string;
	descrizione_it: string;
	descrizione_en: string;
	media: string;
	service_category_it: string;
	service_category_en: string;
}

interface ProjectDataProps {
	project_intestation: ProjectIntestation[];
	services_category: ServicesCategory[];
	occupyTabs: OccupyTab[];
	brandTabs: BrandTab[];
	talents: Talent[];
	organize_events: OrganizeEvent[];
	discover_studio: DiscoverStudio[];
	additive_services: AdditiveService[];
}

const getProjectData = (): Promise<ProjectDataProps> => {
	return fetch(BASE_URL + "progetti")
		.then(res => res.json())
		.then(data => data);
};

export { getProjectData, ProjectDataProps, OrganizeEvent, AdditiveService };

interface Case {
	id: number;
	media_principale: string;
	nome_progetto_it: string;
	titolo_it: string;
	descrizione_titolo_it: string;
	tipologia_it: string;
	visualizzazioni_box_descrizione_it: string;
	visualizzazioni_numero: number;
	like_box_descrizione_it: string;
	like_numero: number;
	talenti_box_descrizione: string;
	talenti_numero: number;
	media_landing: string;
	titolo_media_landing_it: string;
	descrizione_media_landing_it: string;
	content_1_vertical_url: string;
	content_2_vertical_url: string;
	content_3_vertical_url: string;
	cases_1: RelatedCase | null;
	cases_2: RelatedCase | null;
	cases_3: RelatedCase | null;
	cases_4: RelatedCase | null;
	nome_progetto_en: string;
	titolo_en: string;
	descrizione_titolo_en: string;
	tipologia_en: string;
	visualizzazioni_box_descrizione_en: string;
	like_box_descrizione_en: string;
	titolo_media_landing_en: string;
	descrizione_media_landing_en: string | null;
	caso_successo: string;
}

interface RelatedCase {
	id: number;
	titolo_it: string;
	titolo_en: string;
	media_principale: string;
}

interface Category {
	id: number;
	name_it: string;
	name_en: string;
}

interface Sottocategory {
	id: number;
	name_it: string;
	name_en: string;
}

interface CaseDataProps {
	cases: Case[];
	categories: Category[];
	sottocategories: Sottocategory[];
}
interface notLanguageCase {
	id: number;
	nome_progetto: string;
	media_principale: string;
	titolo: string;
	descrizione_titolo: string;
	tipologia: string;
	visualizzazioni_box_descrizione: string;
	visualizzazioni_numero: number;
	like_box_descrizione: string;
	like_numero: number;
	talenti_box_descrizione: string;
	talenti_numero: number;
	media_landing: string;
	titolo_media_landing: string;
	descrizione_media_landing: string | null;
	content_1_vertical_url: string;
	content_2_vertical_url: string;
	content_3_vertical_url: string;
	cases_1: RelatedCase | null;
	cases_2: RelatedCase | null;
	cases_3: RelatedCase | null;
	cases_4: RelatedCase | null;
	caso_successo: string;
}

const getCaseData = (): Promise<CaseDataProps> => {
	return fetch(BASE_URL + "cases")
		.then(res => res.json())
		.then(data => data);
};
const getTalentsPageData = (): Promise<TalentPageDataProps> => {
	return fetch(BASE_URL + "page-talents")
		.then(res => res.json())
		.then(data => data);
};
const fromCaseToNotLanguageCase = (
	caseData: Case | null,
	lang: string
): notLanguageCase | null => {
	if (!caseData) return null;
	return {
		id: caseData.id,
		titolo: lang === "it" ? caseData.titolo_it : caseData.titolo_en,
		nome_progetto:
			lang === "it"
				? caseData.nome_progetto_it
				: caseData.nome_progetto_en,
		media_principale: caseData.media_principale,
		descrizione_titolo:
			lang === "it"
				? caseData.descrizione_titolo_it
				: caseData.descrizione_titolo_en,
		visualizzazioni_numero: caseData.visualizzazioni_numero,
		content_1_vertical_url: caseData.content_1_vertical_url,
		content_2_vertical_url: caseData.content_2_vertical_url,
		cases_1: caseData.cases_1,
		cases_2: caseData.cases_2,
		cases_3: caseData.cases_3,
		cases_4: caseData.cases_4,
		caso_successo: caseData.caso_successo,
		content_3_vertical_url: caseData.content_3_vertical_url,
		media_landing: caseData.media_landing,
		titolo_media_landing:
			lang === "it"
				? caseData.titolo_media_landing_it
				: caseData.titolo_media_landing_en,
		descrizione_media_landing:
			lang === "it"
				? caseData.descrizione_media_landing_it
				: caseData.descrizione_media_landing_en,
		tipologia:
			lang === "it" ? caseData.tipologia_it : caseData.tipologia_en,
		visualizzazioni_box_descrizione:
			lang === "it"
				? caseData.visualizzazioni_box_descrizione_it
				: caseData.visualizzazioni_box_descrizione_en,
		like_box_descrizione:
			lang === "it"
				? caseData.like_box_descrizione_it
				: caseData.like_box_descrizione_en,
		like_numero: caseData.like_numero,
		talenti_box_descrizione:
			lang === "it"
				? caseData.talenti_box_descrizione
				: caseData.talenti_box_descrizione_en,
		talenti_numero: caseData.talenti_numero,
	};
};
export {
	getCaseData,
	CaseDataProps,
	Case,
	getTalentsPageData,
	notLanguageCase,
	fromCaseToNotLanguageCase,
};
